import React from 'react'

const Tg = () => {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.895928 4.6492C4.39417 3.12507 6.72689 2.12028 7.89406 1.63481C11.2266 0.248696 11.9191 0.00791349 12.3704 -3.72972e-05C12.4697 -0.00178598 12.6916 0.0228157 12.8354 0.139479C12.9568 0.237987 12.9902 0.371057 13.0062 0.464453C13.0222 0.55785 13.0421 0.770609 13.0263 0.936853C12.8457 2.83433 12.0643 7.43902 11.6667 9.56423C11.4985 10.4635 11.1673 10.765 10.8466 10.7945C10.1498 10.8586 9.6206 10.334 8.94565 9.89154C7.88949 9.19922 7.29283 8.76825 6.26765 8.09267C5.08287 7.31192 5.85092 6.88281 6.52612 6.18152C6.70282 5.99798 9.77321 3.20523 9.83264 2.95188C9.84007 2.9202 9.84697 2.80209 9.7768 2.73972C9.70664 2.67736 9.60308 2.69869 9.52835 2.71565C9.42242 2.73969 7.7352 3.85488 4.46668 6.06122C3.98776 6.39008 3.55398 6.55031 3.16532 6.54191C2.73686 6.53265 1.91267 6.29965 1.29997 6.10049C0.548472 5.8562 -0.0488066 5.72705 0.0032029 5.31218C0.0302927 5.09609 0.327868 4.8751 0.895928 4.6492Z"
            fill="#475FB7"/>
    </svg>

  )
}

export default Tg