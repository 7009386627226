import React from 'react';

const Language = () => {
  return (
    <div className="language">
      RU
    </div>
  );
};

export default Language;