import * as t from "../types"
import axios from "axios";

export const load_user = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            const res = await axios.get(`https://art-bid.ru/auth/users/me/`, config);

            dispatch({
                type: t.USER_LOADED_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: t.USER_LOADED_FAIL
            });
        }
    } else {
        dispatch({
            type: t.USER_LOADED_FAIL
        });
    }
};

export const checkAuthenticated = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({ token: localStorage.getItem('access') });

        try {
            const res = await axios.post(`https://art-bid.ru/auth/jwt/verify/`, body, config)

            if (res.data.code !== 'token_not_valid') {
                dispatch({
                    type: t.AUTHENTICATED_SUCCESS
                });
                dispatch(load_user());
            } else {
                dispatch({
                    type: t.AUTHENTICATED_FAIL
                });
            }
        } catch (err) {
            dispatch({
                type: t.AUTHENTICATED_FAIL
            });
        }

    } else {
        dispatch({
            type: t.AUTHENTICATED_FAIL
        });
    }
};

export const login = ({email, password}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email, password });

    try {
        const res = await axios.post(`https://art-bid.ru/auth/jwt/create/`, body, config);

        dispatch({
            type: t.LOGIN_SUCCESS,
            payload: res.data
        });

        dispatch(load_user());
    } catch (err) {
        dispatch({
            type: t.LOGIN_FAIL
        })
    }
};

export const signup = (first_name, last_name, email, password, re_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ first_name, last_name, email, password, re_password });

    try {
        const res = await axios.post(`https://art-bid.ru/auth/users/`, body, config);

        dispatch({
            type: t.SIGNUP_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: t.SIGNUP_FAIL
        })
    }
};

export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token });

    try {
        await axios.post(`https://art-bid.ru/auth/users/activation/`, body, config);

        dispatch({
            type: t.ACTIVATION_SUCCESS,
        });
    } catch (err) {
        dispatch({
            type: t.ACTIVATION_FAIL
        })
    }
};

export const reset_password = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email });

    try {
        await axios.post(`https://art-bid.ru/auth/users/reset_password/`, body, config);

        dispatch({
            type: t.PASSWORD_RESET_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: t.PASSWORD_RESET_FAIL
        });
    }
};

export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
        await axios.post(`https://art-bid.ru/auth/users/reset_password_confirm/`, body, config);

        dispatch({
            type: t.PASSWORD_RESET_CONFIRM_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: t.PASSWORD_RESET_CONFIRM_FAIL
        });
    }
};

export const logout = () => dispatch => {
    dispatch({
        type: t.LOGOUT
    });
};